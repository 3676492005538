import RandomActionHelpRequest from '@/views/engine/randomcomponent/actions/helpRequestItem';
import RandomActionTaskFinished from '@/views/engine/randomcomponent/actions/taskFinishedItem';
import RandomActionReopenTask from '@/views/engine/randomcomponent/actions/taskReopenItem';
import RandomActionArchiveTask from '@/views/engine/randomcomponent/actions/taskArchiveItem';
import RandomActionSendDataToModal from '@/views/engine/randomcomponent/actions/sendDataToModal';
import RandomActionStatusRatebox from '@/views/engine/randomcomponent/actions/statusRatebox';
import RandomFilterAction from '@/views/engine/randomcomponent/randomFilterAction';
import RandomSearch from '@/views/engine/randomcomponent/randomSearch';
import DragDropComponent from '@/views/engine/components/dragDropComponent';
import RandomFilterWhoNeedsHelp from '@/views/engine/randomcomponent/randomFilterWhoNeedsHelp';
import { xhrLoad } from '@/views/engine/randomcomponent/xhrLoad';
import $ from 'jquery';

export default class RandomComponent {
  /**
   * Constructor for the random component class
   * @param randomEl {Element}
   */
  constructor(randomEl) {
    this.dom = {
      randomEl,
      dragComponentEl: randomEl.querySelector('.drag-component'),
      gridSearchFormEl: randomEl.querySelector('.js-grid-search'),
      helpActionItems: [...randomEl.querySelectorAll('.js-help-requested, .js-help-provided')],
      finishActionItems: [...randomEl.querySelectorAll('.js-task-finished')],
      reopenActionItems: [...randomEl.querySelectorAll('.js-reopen-task')],
      archiveActionItems: [...randomEl.querySelectorAll('.js-archive-task')],
      filterItemsFormEl: [...randomEl.querySelectorAll('.js-filter-item')],
      filterWhoNeedsHelpEl: randomEl.querySelector('.js-filter-who-needs-help'),
      ratingBoxItems: [...randomEl.querySelectorAll('.rating-component')],
      dataToModalItems: [...randomEl.querySelectorAll('.dataToModal')],
    };
    this.props = {
      randomId: randomEl.dataset.randomid,
      ajaxUrl: randomEl.dataset.ajaxurl,
    };
    this.components = {
      search: null,
      drag: null,
      ratingBoxes: [],
      helpActions: [],
      finishActions: [],
      reopenActions: [],
      archiveActions: [],
      filterActions: [],
      dataToModalActions: [],
      whoNeedsHelpAction: null,
    };

    // bind all actions
    this.mount();
  }

  // bind the elements & actions
  mount() {
    console.log('mount random component', this.props.randomId);

    // add search instance
    if (this.dom.gridSearchFormEl) {
      this.components.search = new RandomSearch({
        dom: {
          gridSearchFormEl: this.dom.gridSearchFormEl,
        },
        props: this.props,
      });
    }

    // add filter who needs help instance
    if (this.dom.filterWhoNeedsHelpEl) {
      this.components.whoNeedsHelpAction = new RandomFilterWhoNeedsHelp(this.dom.filterWhoNeedsHelpEl);
    }

    if (this.dom.dragComponentEl) {
      this.components.drag = new DragDropComponent(this.dom.dragComponentEl);
    }

    // add help instances
    this.dom.helpActionItems.forEach((helpAction) => {
      const helpComponent = new RandomActionHelpRequest(helpAction);
      this.components.helpActions.push(helpComponent);
    });

    // add task instances
    this.dom.finishActionItems.forEach((finishAction) => {
      const finishComponent = new RandomActionTaskFinished(finishAction);
      this.components.finishActions.push(finishComponent);
    });

    // add task instances
    this.dom.reopenActionItems.forEach((reopenAction) => {
      const reopenComponent = new RandomActionReopenTask(reopenAction);
      this.components.reopenActions.push(reopenComponent);
    });

    // add archive task instances
    this.dom.archiveActionItems.forEach((archiveAction) => {
      const archiveComponent = new RandomActionArchiveTask(archiveAction);
      this.components.archiveActions.push(archiveComponent);
    });

    // add rating box instances
    this.dom.ratingBoxItems.forEach((ratingBox) => {
      const ratingComponent = new RandomActionStatusRatebox(ratingBox);
      this.components.ratingBoxes.push(ratingComponent);
    });

    // send data to modal actions
    if (this.components.dataToModalActions) {
      this.dom.dataToModalItems.forEach((sendDataAction) => {
        const dataToModalComponent = new RandomActionSendDataToModal(sendDataAction);
        this.components.dataToModalActions.push(dataToModalComponent);
      });
    }

    // add  filter instance
    if (this.dom.filterItemsFormEl) {
      this.components.filterActions = new RandomFilterAction({
        dom: {
          esFormEl: this.dom.filterItemsFormEl,
        },
        props: this.props,
      });
    }

    // xhr load
    xhrLoad();
  }

  // unbind all elements & actions
  unmount() {
    console.log('unmount random component');

    // destroy search instance
    if (this.components.search) {
      this.components.search.unmount();
    }

    // destroy drag elements
    if (this.components.drag) {
      this.components.drag.unmount();
    }

    // destroy filter help only instance
    if (this.components.whoNeedsHelpAction) {
      this.components.whoNeedsHelpAction.unmount();
    }

    // destroy help instances
    this.components.helpActions.forEach((helpAction) => {
      helpAction.unmount();
    });

    // destroy send data to modal actions
    if (this.components.dataToModalActions) {
      this.components.dataToModalActions.forEach((sendDataAction) => {
        sendDataAction.unmount();
      });
    }

    // destroy task instances
    this.components.finishActions.forEach((finishAction) => {
      finishAction.unmount();
    });

    // destroy reopen instances
    this.components.reopenActions.forEach((reopenAction) => {
      reopenAction.unmount();
    });

    // destroy archive instances
    this.components.archiveActions.forEach((archiveAction) => {
      archiveAction.unmount();
    });

    // destroy rating instances
    this.components.ratingBoxes.forEach((ratingBox) => {
      ratingBox.unmount();
    });

    // remove filter instance
    if (this.components.filterActions) {
      this.components.filterActions.unmount();
    }
  }
}
