export default class RandomActionSendDataToModal {
  /**
   * set an item on of off
   * @param action {Element}
   */
  constructor(action) {
    this.dom = {
      action,
      engine: document.getElementById('engine'),
    };
    this.events = {
      sendDataAction: this.sendData.bind(this),
    };

    // bind the events
    this.mount();
  }

  // add event listener
  mount() {
    this.dom.action.addEventListener('click', this.events.sendDataAction);
  }

  // remove event listener
  unmount() {
    this.dom.action.removeEventListener('click', this.events.sendDataAction);
  }

  /**
   * send some data
   *
   * @param e {MouseEvent}
   */
  sendData(e) {
    e.preventDefault();

    const rateBoxes = document.querySelectorAll('.rate-box');
    rateBoxes.forEach((rateBox) => {
      rateBox.classList.add('d-none');
    });

    const modal = document.querySelector(e.target.dataset.target);
    const saveBtn = modal.querySelector('button[name="save"]');
    saveBtn.dataset.pair = e.target.dataset.sendasPair;
    const aTemp = e.target.dataset.sendasPair.split('#');
    // eslint-disable-next-line prefer-destructuring
    saveBtn.dataset.taskid = aTemp[1];
  }
}
